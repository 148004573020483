
import { Component, Vue } from "vue-property-decorator";
import Carousel from "@/components/Home/Carousel.vue";
import DemoSwiper from "@/components/Home/MobileSwiper.vue";
import EnterprisePartner from "@/components/Academy/Utility/EnterprisePartner.vue";
import TigerSchoolFootBanner from "@/components/Academy/Utility/TigerSchoolFootBanner.vue";

@Component({
  components: {
    Carousel,
    DemoSwiper,
    EnterprisePartner,
    TigerSchoolFootBanner
  },
})
export default class AcademyPage extends Vue {
  device = 2;
  footbannerOption = {
    btnText: "企業合作",
    linkUrl: "/Academy/EnterpriseEnquire",
    cssProps: {
      backgroundImage: `url(${require("@/assets/banner/academy.jpg")})`,
    },
  };
}
