
import { Component, Vue } from "vue-property-decorator";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
@Component({
  components: { Swiper, SwiperSlide },
})
export default class EnterprisePartner extends Vue {
  //data
  now = 0;
  prepage = 3;
  nowpage = 1;
  isActive: number | null = 1;
  //clickPage = false;
  cooperationlist: any = [
    {
      id: "1",
      title: "凱撒x師虎 合作培訓",
      desc: "學習基礎凱撒衛浴的基礎安裝過程與維修方法,了解安裝五金件及工具使用方式。",
      tag: "凱撒衛浴",
      img: require("../../../../public/fdtiger-offical-site-img/tigerschool/home/logo/003.png"),
    },
    {
      id: "2",
      title: "職訓局x師虎 合作培訓",
      desc: "師虎來了與臺北市職能發展學院合作，在台北市開課。希望能將有更多時段供各位求職者學習。",
      tag: "臺北市職能發展學院",
      img: require("../../../../public/fdtiger-offical-site-img/tigerschool/home/logo/004.png"),
    },
    {
      id: "3",
      title: "和成x師虎 合作培訓",
      desc: "透過課程學習到各種水設施原理與種類，現場並實際操作 。根據不同的現場狀況做出專業判斷。",
      tag: "和成衛浴",
      img: require("../../../../public/fdtiger-offical-site-img/tigerschool/home/logo/005.png"),
    },
    {
      id: "4",
      title: "職訓局x師虎 合作培訓",
      desc: "師虎來了與臺北市職能發展學院合作，在台北市開課。希望能將有更多時段供各位求職者學習。",
      tag: "臺北市職能發展學院",
      img: require("../../../../public/fdtiger-offical-site-img/tigerschool/home/logo/004.png"),
    },
    {
      id: "5",
      title: "和成x師虎 合作培訓",
      desc: "透過課程學習到各種水設施原理與種類，現場並實際操作 。根據不同的現場狀況做出專業判斷。",
      tag: "和成衛浴",
      img: require("../../../../public/fdtiger-offical-site-img/tigerschool/home/logo/005.png"),
    },
    {
      id: "6",
      title: "凱撒x師虎 合作培訓",
      desc: "學習基礎凱撒衛浴的基礎安裝過程與維修方法,了解安裝五金件及工具使用方式。",
      tag: "凱撒衛浴",
      img: require("../../../../public/fdtiger-offical-site-img/tigerschool/home/logo/003.png"),
    },
    {
      id: "7",
      title: "和成x師虎 合作培訓",
      desc: "透過課程學習到各種水設施原理與種類，現場並實際操作 。根據不同的現場狀況做出專業判斷。",
      tag: "和成衛浴",
      img: require("../../../../public/fdtiger-offical-site-img/tigerschool/home/logo/005.png"),
    },
    {
      id: "8",
      title: "凱撒x師虎 合作培訓",
      desc: "學習基礎凱撒衛浴的基礎安裝過程與維修方法,了解安裝五金件及工具使用方式。",
      tag: "凱撒衛浴",
      img: require("../../../../public/fdtiger-offical-site-img/tigerschool/home/logo/003.png"),
    },
    {
      id: "9",
      title: "職訓局x師虎 合作培訓",
      desc: "師虎來了與臺北市職能發展學院合作，在台北市開課。希望能將有更多時段供各位求職者學習。",
      tag: "臺北市職能發展學院",
      img: require("../../../../public/fdtiger-offical-site-img/tigerschool/home/logo/004.png"),
    },
  ];
  swiperOption2 = {
    slidesPerView: "1.5",
    spaceBetween: 16,
    centeredSlides: false,
    slidesOffsetBefore: 30,
    loop: true,
    followFinger: false,
    pagination: {
      el: ".swiper-pagination.swp2",
      type: "bullets",
      clickable: true,
    },
  };
  swiperList: any = [
    {
      id: "1",
      title: "凱撒x師虎 合作培訓",
      desc: "學習基礎凱撒衛浴的基礎安裝過程與維修方法,了解安裝五金件及工具使用方式。",
      tag: "凱撒衛浴",
      img: require("../../../../public/fdtiger-offical-site-img/tigerschool/home/logo/003.png"),
    },
    {
      id: "2",
      title: "職訓局x師虎 合作培訓",
      desc: "師虎來了與臺北市職能發展學院合作，在台北市開課。希望能將有更多時段供各位求職者學習。",
      tag: "臺北市職能發展學院",
      img: require("../../../../public/fdtiger-offical-site-img/tigerschool/home/logo/004.png"),
    },
    {
      id: "3",
      title: "和成x師虎 合作培訓",
      desc: "透過課程學習到各種水設施原理與種類，現場並實際操作 。根據不同的現場狀況做出專業判斷。",
      tag: "和成衛浴",
      img: require("../../../../public/fdtiger-offical-site-img/tigerschool/home/logo/005.png"),
    },
  ];

  //computed
  get allImages(): {
    id: string;
    title: string;
    desc: string;
    tag: string;
    img: string;
  }[] {
    // 3 + 4
    const ary = [];
    const total = this.cooperationlist.length;
    let count;
    if (total > 0) {
      while (ary.length < 3 + 4) {
        count = Math.floor(ary.length / total);
        for (let i = 0; i < total; i++) {
          ary.push({
            id: count + this.cooperationlist[i].id,
            title: this.cooperationlist[i].title,
            desc: this.cooperationlist[i].desc,
            tag: this.cooperationlist[i].tag,
            img: this.cooperationlist[i].img,
          });
        }
      }
    }
    return ary;
  }
  get showImages(): {
    id: string;
    title: string;
    desc: string;
    tag: string;
    img: string;
  }[] {
    const start = this.now - 4;
    return this.allImages.slice(start).concat(this.allImages.slice(0, start));
  }

  //page點點會出現的那幾張
  get pageslist(): {
    id: string;
    title: string;
    desc: string;
    tag: string;
    img: string;
  }[] {
    let start = (this.nowpage - 1) * this.prepage;
    return this.cooperationlist.slice(start, start + this.prepage);
  }
  //總共頁數
  get totalpage(): number {
    return Math.ceil(this.cooperationlist.length) / this.prepage;
  }
  //合作夥伴按鈕點點
  changePages(item: number): void {
    //this.clickPage = true;
    let page = item;
    if (page < 1) {
      this.nowpage = 1;
    } else if (page > this.totalpage) {
      this.nowpage = this.totalpage;
    } else {
      this.nowpage = page;
    }
    this.isActive = page;
  }
  //合作夥伴按鈕箭頭
  moveOnePage(move: number): void {
    let page = this.nowpage + move;
    if (page < 1) {
      this.nowpage = this.totalpage;
      this.isActive = this.totalpage;
    } else if (page > this.totalpage) {
      this.nowpage = 1;
      this.isActive = 1;
    } else {
      this.nowpage = page;
      this.isActive = page;
    }
  }
}
